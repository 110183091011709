import React, { useState } from 'react';
import axios from 'axios';

function ReferencedBible() {
  const [bibleData, setBibleData] = useState(null);
  const [profileData, setProfileData] = useState(null); // just for testing
  const [searchQuery, setSearchQuery] = useState(""); // new state for search input
  const BASE_URL = "http://localhost:8080";
  const testReference = "/Genesis/1/3-4";

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  };

  const sideBoxesStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  };

  const bigBoxStyle = {
    width: '310px',
    height: '590px',
    backgroundColor: 'skyblue',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '22px',
    margin: '0 20px',
    padding: '20px',
    color: 'white'
  };

  const smallBoxStyle = {
    width: '183px',
    height: '183px',
    backgroundColor: 'lightgrey',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    margin: '10px 0'
  };

  const jsonTest = {
    "name": "John Doe",
    "age": 32,
    "email": "johndoe@example.com"
  };
  const inputStyle = {
    padding: '10px 15px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginBottom: '10px'
  };

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#87CEEB',
    color: 'white',
    cursor: 'pointer',
    marginBottom: '10px'
  };

  const paragraphStyle = {
    color: '#333',
    fontSize: '18px',
    marginBottom: '20px'
  };
 
  function getProfileData() { //for testing
      axios.get("http://127.0.0.1:8080/data", {
      })
      .then((response) => {
        const res = response.data
        setProfileData(({
          profile_name: res.name,
          about_me: res.about
        }))
      }).catch((error) => {
        if (error.response) {
          console.log(error.response)
          console.log(error.response.status)
          console.log(error.response.headers)
          }
      });
  }

  function getBibleData() {
      console.log("ping server for verse")
      const newUrl = BASE_URL + testReference; 

      axios.get(newUrl).then((response) => {
        const res = response.data        
        console.log("pinging server for verse")
        setBibleData(({
          text: res.text,
        }))
      }).catch((error) => {
        if (error.response) {
          console.log(error.response)
          console.log(error.response.status)
          console.log(error.response.headers)
          }
    })}

    function handleSearchChange(event) {
      setSearchQuery(event.target.value);
    }

    return (
      <div>
        <p style={paragraphStyle}>To search: </p>
        <input 
          style={inputStyle}
          type="text" 
          value={searchQuery} 
          onChange={handleSearchChange} 
          placeholder="Search..."
        />
        <p style={paragraphStyle}>To get your profile details: </p>
        <button style={buttonStyle} onClick={getProfileData}>Click me</button>
  
        <p style={paragraphStyle}>To get our test verse: </p>
        <button style={buttonStyle} onClick={getBibleData}>Pull Verses</button>  
        {profileData && (
          <div>
            <p>Profile name: {profileData.profile_name}</p>
            <p>About me: {profileData.about_me}</p>
          </div>
        )}
        {bibleData && (
          <div>
            <p>verse referenced is {testReference}</p>
            <p>verse: {bibleData.text}</p>
          </div>
        )}
        
        <div style={containerStyle}>
          <div style={sideBoxesStyle}>
            <div style={smallBoxStyle}></div>
            <div style={smallBoxStyle}></div>
            <div style={smallBoxStyle}></div>
          </div>
          <div style={bigBoxStyle}>
            1 In the beginning God created the heavens and the earth. 2 Now the earth was formless and empty, darkness was over the surface of the deep, and the Spirit of God was hovering over the waters. 3 And God said, “Let there be light,” and there was light. 4 God saw that the light was good, and he separated the light from the darkness. 5 God called the light “day,” and the darkness he called “night.” And there was evening, and there was morning—the first day. ☀️
          </div>
          <div style={sideBoxesStyle}>
            <div style={smallBoxStyle}></div>
            <div style={smallBoxStyle}></div>
            <div style={smallBoxStyle}></div>
          </div>
        </div>
      </div>
    );
  }
  
  export default ReferencedBible;